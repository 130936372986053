export const submitOrder = function (plan) {
  const stripeConfig = stripeConfigMap[plan.mode]
  const stripe = Stripe(stripeConfig.key)

  const origin = window.location.origin
  let search = window.location.search

  const successUrl = `${origin}/thank-you`
  if ("URLSearchParams" in window) {
    let params = new URLSearchParams(search)
    params.set("plan", plan.key)
    search = "?" + params.toString()
  }
  const cancelUrl = `${origin}${window.location.pathname}${search}`

  function getClientReferenceId() {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      "checkout_" + new Date().getTime()
    )
  }

  stripe
    .redirectToCheckout({
      items: [{ plan: plan.id, quantity: 1 }],
      successUrl,
      cancelUrl,
      clientReferenceId: getClientReferenceId(),
    })
    .then((result) => {
      if (result.error) {
        alert(result.error)
      }
    })
    .catch((e) => alert(e.message))
}

const STRIPE_MODE_LIVE = "live"
const STRIPE_MODE_TEST = "test"
const stripeConfigMap = {
  [STRIPE_MODE_TEST]: {
    key: "pk_test_bRLkBTznXKkbiACf79UrRRvL",
  },
  [STRIPE_MODE_LIVE]: {
    key: "pk_live_0ute0DTa8hbsfy8OkpoL3QX2",
  },
}
