import "./main.css"
import { Elm } from "./Main.elm"
import * as serviceWorker from "./serviceWorker"
import { submitOrder } from "../vendor/payment-widget/src/ports"

const STORAGE_KEY = "store"

var app = Elm.Main.init({
  node: document.getElementById("root"),
})

app.ports.submitOrder.subscribe(submitOrder)

if (!app.ports.sendFBEvent) {
    console.warn("Unable to initialize sendFB port.")
} else {
    app.ports.sendFBEvent.subscribe(function (event) {
        console.log('track', event)
        fbq(event.type, event.name);
    })
}

if (!app.ports.sendAnalyticsEvent) {
    console.warn("Unable to initialize sendGA port.")
} else {
    app.ports.sendAnalyticsEvent.subscribe(function (event) {
        console.log('sendAnalyticsEvent',event)
        gtag("event", event.name, event.params)
    })
}


/* Cache Port
 *
 * This subscription will listen on `storeCache` events and store the `val`
 * in Local Storage under `storageKey` key.
 *
 * When Local Storage changes for this domain - it will send the new store
 * via `onStoreChange` incoming port.
 */
if (!app.ports.storeCache || !app.ports.onStoreChange) {
    console.warn("Unable to initialize cache port.")
}else{
    // Listen for `storeCache` events and store them in LS
    app.ports.storeCache.subscribe(val => {
        if (val === null) {
            localStorage.removeItem(STORAGE_KEY)
        } else {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(val))
        }
        // Report that the new session was stored succesfully.
        setTimeout(() => {
            app.ports.onStoreChange.send(val)
        }, 0)
    })

    // Whenever localStorage changes in another tab, report it if necessary.
    window.addEventListener(
        "storage",
        event => {
            if (event.storageArea === localStorage && event.key === STORAGE_KEY) {
                try {
                    const newStore = JSON.parse(event.newValue)
                    app.ports.onStoreChange.send(newStore)
                } catch (e) {
                    console.warn(e)
                }
            }
        },
        false
    )
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
